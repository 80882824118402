.table_body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  margin-bottom: 20px;
}

.table_body::-webkit-scrollbar {
  display: none;
}

.table_body_header {
  margin-bottom: 40px;
}

.table_body_header,
.table_body_body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 1200px;
}

.table_body_body {
  padding: 30px 0;
  border-bottom: 1px solid var(--color2);
  position: relative;
}

.table_body_header_item_2 {
  width: 7% !important;
}

.table_body_header_item_3 {
  width: 13% !important;
}

.table_body img {
  width: 34px;
  height: 34px;
}

.table_body img {
  width: 30px;
  height: 30px;
}

.table_body_header > div,
.table_body_body > div {
  margin: 0 10px;
  width: 6%;
}

.orders .table_body_header > div,
.orders .table_body_body > div,
.orders .table_body_header_item_2,
.table_body_header_item_1 {
  width: 5%;
}

.customers .table_body_header > div,
.customers .table_body_body > div {
  width: 8.6%;
}

.orders .table_body_header_item_3 {
  width: 10% !important;
}

.customers .table_body_header_item_9,
.delivery_workers .table_body_header_item_9,
.promotion_sect .table_body_header_item_6 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.delivery_workers .table_body_body > div,
.delivery_workers .table_body_header > div {
  width: 8.8%;
}

.orders .table_body_header_item_4,
.orders .table_body_header_item_6,
.orders .table_body_header_item_7,
.table_body_header_item_4,
.customers .table_body_header_item_6,
.customers .table_body_header_item_3,
.customers .table_body_header_item_4 {
  width: 15% !important;
}

.orders .table_body_header > div,
.orders .table_body_body > div {
  width: 9%;
}

.orders .table_body_header_item_5 {
  width: 25% !important;
}

.orders .table_body_header_item_7,
.orders .table_body_header_item_4 {
  width: 15% !important;
}

.table_body_header_text {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color1);
}

.customers_transaction_details .table_body_body > div,
.customers_transaction_details .table_body_header > div {
  width: 14.3%;
}

.proof {
  height: 70px !important;
  width: 70px !important;
}

.update_user_deposit_status {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.update_user_deposit_status button {
  height: 30px;
  width: 100px;
  border: none;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}

.wallet_address .table_body_header_item_1 {
  width: 5% !important;
}

.wallet_address .table_body_header_item_2 {
  width: 10% !important;
}

.wallet_address .table_body_header_item_5,
.wallet_address .table_body_header_item_4 {
  width: 20% !important;
}

.wallet_address .table_body_header_item_3 {
  width: 45% !important;
}

.wallet_address .table_body_body,
.wallet_address .table_body_header {
  min-width: 100% !important;
}

.update_wallet_btn {
  width: 100%;
  height: 30px;
  background-color: var(--secondary-color);
  color: white;
  border: none;
  border-radius: 5px;
}

.kyc_table_body_header,
.kyc_table_body_body {
  min-width: auto;
}

.kyc_table_body_header > div,
.kyc_table_body_body > div {
  width: 20%;
}

.loan_table_body_body,
.loan_table_body_header {
  min-width: 1700px !important;
}

.loan_table_body_body > .table_body_header_item_1,
.loan_table_body_header > .table_body_header_item_1 {
  width: 3%;
}
.loan_table_body_body > .table_body_header_item_2,
.loan_table_body_header > .table_body_header_item_2,
.loan_table_body_body > .table_body_header_item_3,
.loan_table_body_header > .table_body_header_item_3,
.loan_table_body_body > .table_body_header_item_4,
.loan_table_body_header > .table_body_header_item_4,
.loan_table_body_body > .table_body_header_item_5,
.loan_table_body_header > .table_body_header_item_5 {
  width: 7% !important;
}

.loan_table_body_body > .table_body_header_item_6,
.loan_table_body_header > .table_body_header_item_6,
.loan_table_body_body > .table_body_header_item_10,
.loan_table_body_header > .table_body_header_item_10,
.loan_table_body_body > .table_body_header_item_9,
.loan_table_body_header > .table_body_header_item_9 {
  width: 15%;
}

.loan_table_body_body > .table_body_header_item_8,
.loan_table_body_header > .table_body_header_item_8,
.loan_table_body_body > .table_body_header_item_11,
.loan_table_body_header > .table_body_header_item_11,
.loan_table_body_body > .table_body_header_item_5,
.loan_table_body_header > .table_body_header_item_5 {
  width: 10% !important;
}
